@font-face {
  font-family: 'Roboto', sans-serif;
  src: url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #3a3938;
  font-family: 'Interstate', sans-serif;
  height: 100vh;
  width: 100vw;
}

#root {
  height: 100%;
}

/* a tag reset */
a {
  color: unset;
  text-decoration: none;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fbfdfb;
  -webkit-box-shadow: 0 0 0px 1000px #3a3938 inset;
  transition: background-color 5000s ease-in-out 0s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

canvas#deckgl-overlay {
  cursor: default !important;
}
